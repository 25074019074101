import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Head from "../components/Head";
import Footer from "../components/Footer";
import BoxTypo from "../components/BoxTypo";
import { Box, Grid, Button, Stack } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useForm } from "react-hook-form";
import Forms from "../components/Forms";
import ValidationView from "../components/FormsValidate";
import ShippingModal from "../components/ShippingModal";
import "../style/forms.css";
import "../style/base.css";
import { createClient } from "microcms-js-sdk";
import {
  StaticImage,
  GatsbyImage,
  getImage,
  DynamicImage,
} from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const H2 = (props) => {
  return (
    <BoxTypo
      m="56px auto"
      fs={["24px", "26px"]}
      ls={["2px"]}
      lh={["200%"]}
      fw={["500"]}
      color={"#515D59"}
      className="tac"
    >
      {props.children}
    </BoxTypo>
  );
};
const H3 = (props) => {
  return (
    <BoxTypo
      {...props}
      mt={6}
      fs={["18px", "20px"]}
      ls={["1px"]}
      lh={["200%"]}
      fw="500"
      color={"#515D59"}
    >
      {props.children}
    </BoxTypo>
  );
};
const P = (props) => {
  return (
    <BoxTypo
      {...props}
      fs={["14px", "16px"]}
      ls={["2px"]}
      lh={["200%"]}
      color={props.color ? props.color : "#515D59"}
    >
      {props.children}
    </BoxTypo>
  );
};

const Main = () => {
  const [foods, setFoods] = useState([]);
  const tempMenuArray = [];
  const client = createClient({
    serviceDomain: "forustyle",
    apiKey: process.env.API_KEY,
  });

  const [isOpenModal, setOpenModal] = useState(false);
  const [inputData, setData] = useState({});
  const onSubmit = (data) => console.log(data);
  const orderNumSum = (numArray) => {
    var temp = 0;
    numArray.map((c) => {
      temp += c;
    });
    return temp;
  };

  useEffect(() => {
    console.log("came useEffect", inputData);
    client
      .get({
        endpoint: "obento",
        useGlobalDraftKey: false,
      })
      .then((res) => {
        console.log({ res });
        res.contents.map((c) => {
          const tempObj = {
            name: c.title,
            price: c.price,
            img: c.picture.url,
          };
          tempMenuArray.push(tempObj);
        });
        setFoods(tempMenuArray);
      })
      .catch((e) => {
        alert("ネットワークエラーが発生致しました。");
      });
  }, [setFoods]);

  const [orderNum, setOrderNum] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [totalPrice, setTotalPrice] = useState(0);
  const sumPrice = () => {
    var total = 0;
    foods.map((c, i) => {
      total = total + Number(c.price) * orderNum[i];
    });
    setTotalPrice(total);
  };
  return (
    <>
      <Head />
      <Header />
      {foods.length === 0 ? (
        <>
          <Box height="80vh" pt="20vh">
            <P textAlign="center">
              ローディング中です。
              <br />
              しばらくしても表示されない場合は、再度読み込みを実行して下さい。
            </P>
          </Box>
        </>
      ) : (
        <>
          <Box display={isOpenModal ? "none" : "block"}>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <StaticImage
                src="../images/order_top_sp.png"
                style={{ width: "100%", objectFit: "cover" }}
                alt="First View"
              />
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <StaticImage
                src="../images/order_top_pc.png"
                style={{ width: "100%", objectFit: "cover" }}
                alt="First View"
              />
            </Box>
            <Box py={7} width={"100%"} bgcolor={"#F3F5F5"}>
              <Box
                width={"100%"}
                maxWidth={"1100px"}
                px={["10px", "20px"]}
                className="tac ma"
              >
                <P lh={["250%"]}>
                  以下より「お弁当」のご注文を承ります。
                  <br />
                  ご注文個数をご入力いただいた後、お届け先の情報を入力のうえ注文ボタンを押してください。
                  <br />
                  <br />
                  ※ご注文は1週間前まで承ります。1週間以内のご予約をご希望の場合は、forucafe本店までお電話でご相談ください。
                  <br />
                  ※お弁当のご注文は上限200個まで承ります。
                </P>
              </Box>
            </Box>
            <Box
              width={"100%"}
              maxWidth={"1100px"}
              px={["10px", "20px"]}
              className="ma"
            >
              <H2>Menu</H2>
              <H3>お弁当プラン</H3>
              <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]}>
                ロケや会議にピッタリ。木のお弁当箱に、十五穀米をベースにしてお肉やお魚、お野菜のお惣菜を丁寧に詰め込んだお弁当です。
              </BoxTypo>
              <Box width={"100%"} my={7}>
                <Grid container width={"100%"} spacing={[0, 5]}>
                  {foods.map((c, i) => {
                    return (
                      <Grid key={i} item xs={12} sm={4}>
                        <Food
                          price={c.price}
                          name={c.name}
                          id={i}
                          img={c.img}
                          orderNum={orderNum}
                          setOrderNum={setOrderNum}
                          sumPrice={sumPrice}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              {/* <H3>木箱プラン</H3>
              <P>
                少人数〜大人数の集まりには、彩り豊かで身体に良いお惣菜を、木箱に詰めてお届けします。
              </P>
              <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]}>
                ※このプランにはセッティングは含まれておりません。店頭受取または配送のみでのご対応となります。
                セッティングや装飾までご希望の方は、オーダーメイドプランよりご注文ください。
              </BoxTypo> */}
              {/* <Box width={"100%"} my={7}>
              <Grid container width={"100%"} spacing={[0, 5]}>
                {foods.map((c, i) => {
                  return (
                    <Grid key={i} item xs={12} sm={4}>
                      <Food
                        price={c.price}
                        name={c.name}
                        id={i}
                        img={c.img}
                        orderNum={orderNum}
                        setOrderNum={setOrderNum}
                        sumPrice={sumPrice}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box> */}
            </Box>
            <Box py={7} width={"100%"} bgcolor={"#F3F5F5"}>
              <Box
                width={"100%"}
                maxWidth={"1100px"}
                px={["10px", "20px"]}
                className="ma"
              >
                <Box className="tar w100">
                  <H3 className="" mr={["7%"]}>
                    ご注文内容
                  </H3>
                  <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]}>
                    合計：{orderNumSum(orderNum)}点
                  </BoxTypo>
                  <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]}>
                    小計:¥{totalPrice}
                  </BoxTypo>
                  <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]}>
                    配送料別途算出
                  </BoxTypo>
                  <ShippingModal />
                </Box>
              </Box>
            </Box>
            <Box
              id="order-form"
              width={"100%"}
              maxWidth={"1100px"}
              px={["10px", "20px"]}
              className="ma tac"
            >
              <H3>ご注文者様の情報を入力して下さい</H3>
              <Forms
                setOpenModal={setOpenModal}
                setData={setData}
                inputData={inputData}
              />
            </Box>
            <Box
              id="cancel-policy"
              m="56px auto"
              width={"90%"}
              maxWidth={"1100px"}
              className="tac"
              px={["10px"]}
              border={"2px solid #515D59"}
              pb={7}
            >
              <H3>キャンセルについて</H3>
              <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]} mt={4}>
                お申込み後、手配を開始してから何らかの理由でお客様が予約をキャンセルされた場合に生じる
                キャンセル料は、下記の規定に従いお客様にご負担頂きます。
              </BoxTypo>
              <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]}>
                14日前~8日前:ご注文金額の50% 7日前~2日前:ご注文金額の80%
                前日~当日:ご注文金額の100%
              </BoxTypo>
            </Box>
          </Box>
          <Box display={isOpenModal ? "block" : "none"}>
            <ValidationView
              foods={foods}
              inputData={inputData}
              orderNum={orderNum}
              setOpenModal={setOpenModal}
            />
          </Box>
        </>
      )}

      <Footer />
    </>
  );
};

const Food = (props) => {
  const { orderNum, setOrderNum, id, price, name, sumPrice } = props;
  return (
    <Box width={"100%"} mt={3}>
      <img
        alt="img"
        src={props.img}
        style={{ width: "100%", objectFit: "cover" }}
      />
      <Box className="df jcsb" width={"100%"} mt={1}>
        <Box width={"70%"}>
          <BoxTypo
            color={"#515D59"}
            fs={["16px"]}
            ls={["2px"]}
            lh={["200%"]}
            height={"60px"}
          >
            {name}
          </BoxTypo>
          <BoxTypo
            color={"#515D59"}
            fs={["12px"]}
            ls={["2px"]}
            lh={["200%"]}
            fw="300"
          >
            ¥{price}
          </BoxTypo>
        </Box>
        <Box
          width={"60%"}
          className="df jcsb alis tar mr"
          alignItems={"center"}
        >
          <Box
            width={"40%"}
            className="mr"
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            style={{
              height: "100%",
              background: "#F3F5F5",
              color: "#515D59",
              border: "none",
            }}
            height="70px"
          >
            <BoxTypo className="tac" fs={["14px"]} ls={["2px"]} mr={1}>
              {orderNum[id]}
            </BoxTypo>
            <BoxTypo className="tac" fs={["14px"]} ls={["2px"]}>
              個
            </BoxTypo>
          </Box>
          <Box width={"15%"}>
            <button
              style={{
                height: "40px",
                width: "100%",
                border: "none",
                background: "none",
                color: "#515D59",
              }}
              onClick={(e) => {
                var temp = orderNum;
                temp[id] = Number(orderNum[id] < 99 ? orderNum[id] + 1 : 99);
                console.log({ temp, value: orderNum[id] });
                setOrderNum([...temp]);
                sumPrice();
              }}
            >
              <ArrowDropUpIcon />
            </button>
            <button
              style={{
                height: "40px",
                border: "none",
                background: "none",
                color: "#515D59",
              }}
              onClick={(e) => {
                var temp = orderNum;
                temp[id] = Number(orderNum[id] > 0 ? orderNum[id] - 1 : 0);
                console.log({ temp, value: orderNum[id] });
                setOrderNum([...temp]);
                sumPrice();
              }}
            >
              <ArrowDropDownIcon />
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Main;
